.form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .logo {
    display: block;
    margin: 0 auto 20px;
    width: 100px; /* Adjust as needed */
  }

  .navbar-brand img {
    width: 100px; /* Adjust as needed */
  }